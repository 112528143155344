import Amplify from '@aws-amplify/core'
import API from '@aws-amplify/api'

import { amplifyconfig } from '../utils/config'
Amplify.configure(amplifyconfig)

export function saveUnsub(email) {
  let apiName = 'Picopulsemkt'
  let path = 'unsub'

  let options = {
    queryStringParameters: {
      i: email,
    },
  }

  return (dispatch) => {
    dispatch({
      type: 'LOADING_START',
    })
    //begin promise
    new Promise((resolve) => {
      API.get(apiName, path, options)
        .then((response) => {
          const data = response || {}

          console.log('((actions - saveUnsub))')
          console.log(data)

          return resolve(
            dispatch({
              type: 'UNSUB_SUBMITTED',
              data: data,
            })
          )
        })
        .catch((error) => {
          console.log(error.response)
        })
    }) //end promise
  }
}
